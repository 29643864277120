<template>
  <div class="login-screen">
    <form @submit.prevent="handleLogin">
      <h2>Login</h2>

      <div class="input-group">
        <label for="username">Username:</label>
        <input type="text" id="username" v-model="username" required />
      </div>

      <div class="input-group">
        <label for="password">Password:</label>
        <div class="password-wrapper">
          <input :type="showPassword ? 'text' : 'password'" id="password" v-model="password" required />
          <span class="password-toggle" @click="togglePasswordVisibility">👁</span>
        </div>
      </div>

      <button type="submit">Login</button>

      <p v-if="errorMessage">{{ errorMessage }}</p>

      <div>
        <p class="register-link">Don't have an account? <router-link to="/register">Register</router-link></p>
      </div>

      <div v-if="isLoading" class="loading">Logging in...</div>

    </form>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useAuthStore } from '@/store/Auth.js';
import { useRouter } from 'vue-router';
import userService from '@/api/services/userService.js';

export default {
  setup() {
    const username = ref('');
    const password = ref('');
    const errorMessage = ref('');
    const isLoading = ref(false);

    const authStore = useAuthStore();
    const router = useRouter();

    const showPassword = ref(false);

    const togglePasswordVisibility = () => {
      showPassword.value = !showPassword.value;
    };

    const handleLogin = async () => {
      isLoading.value = true;

      //TODO: remove this after test
      router.push('/addcamping');

      try {
        const response = await userService.login(username.value, password.value);

        authStore.login({
          user: { id: response.id, username: response.username },
          token: response.token
        });

        router.push('/addcamping');
      } catch (error) {
        console.log("Error = " + error);

        if (error && error.response && error.response.status) {
          if (error.response.status === 400) {
            errorMessage.value = error.response.data.message;
          } else {
            errorMessage.value = "An error occurred during login.";
          }
        } else {
          errorMessage.value = "An unexpected error occurred.";
        }
      } finally {
        isLoading.value = false;
      }

    };

    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
        handleLogin();
      }
    }

    return {
      username,
      password,
      errorMessage,
      isLoading,
      showPassword,
      togglePasswordVisibility,
      handleKeyPress,
      handleLogin
    };
  }
};
</script>

<style scoped>
.login-screen {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, #f5f7fa, #c3cfe2);
  font-family: 'Arial', sans-serif;
}

h2 {
  text-align: center;
  margin-bottom: 24px;
  font-weight: 600;
  color: #333;
  width: 100%;
}

.input-group {
  margin-bottom: 20px;
  /* increased spacing */
}

label {
  display: block;
  margin-bottom: 8px;
  /* increased spacing */
  font-size: 16px;
  /* increased font size */
  color: #555;
}

input[type="text"],
input[type="password"] {
  width: 100%;
  padding: 14px 12px;
  /* increased padding */
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
  font-size: 16px;
  /* increased font size */
  transition: border-color 0.3s ease;
}

input[type="text"]:focus,
input[type="password"]:focus {
  border-color: #007BFF;
  /* Add focus effect */
}

button {
  width: 100%;
  padding: 14px;
  /* increased padding */
  background-color: #5d740d;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 18px;
  /* increased font size */
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #30881adc;
}

form {
  width: 100%;
  max-width: 400px;
  /* increased width */
  padding: 25px;
  /* increased padding */
  margin: auto;
  background-color: #fff;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  /* increased shadow for depth */
  border-radius: 10px;
  /* rounded edges */
}

p {
  color: red;
  font-size: 16px;
  /* increased font size */
  text-align: center;
  margin-top: 14px;
  /* increased spacing */
}


.register-link {
  color: rgb(107, 106, 106) !important;
}

.password-wrapper {
  position: relative;
  width: 100%;
}

.password-toggle {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.loading {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 20px;
  border-radius: 5px;
  z-index: 1000;
}
</style>