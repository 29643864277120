import axios from 'axios';

const baseURL = process.env.VUE_APP_API_BASE_URL;

const api = axios.create({
    baseURL: baseURL,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
});
 
// Add a request interceptor to axios
api.interceptors.request.use((config) => {
    if (!config.headers.withoutAuth) { 
        const token = localStorage.getItem('jwtToken');
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
    } else {
        delete config.headers.withoutAuth;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

export default api;