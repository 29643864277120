<template>
  <v-card elevation="2" class="pa-3 mb-4">
    <h3 class="text-h6 mb-2">Tag Types</h3>

    <v-btn class="btn-camping" @click="showTagModal = true">Select Tag Types</v-btn>

    <v-chip-group column v-if="localSelectedTags.length" class="mt-3">
      <!-- For each selected tag type, display a chip with a close button -->
      <v-chip v-for="(type) in localSelectedTags" :key="type" closable @click:close="removeTag(type)">
        <!-- Handle chip close event -->
        {{ tagTypeOptions.find(item => item.value === type).title }}
      </v-chip>
    </v-chip-group>

    <!-- Modal for selecting tag types -->
    <v-dialog v-model="showTagModal" max-width="800px">
      <v-card>
        <v-card-title>Select Tag Types</v-card-title>
        <v-card-text>
          <v-row>
            <v-col v-for="(type, key) in tagTypeOptions" :key="key" cols="4">
              <v-checkbox v-model="localSelectedTags" :value="type.value" :label="type.title"></v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="clearTags" color="red">Clear All</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="showTagModal = false" class="btn-camping">Add</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-card>
</template>

<script>
import { ref, watch } from 'vue';

export default {
  name: 'TagTypeSection',
  props: {
    selectedTags: {
      type: Array,
      default: () => []
    },
    tagTypeOptions: {
      type: Array,
      default: () => []
    }
  },
  setup(props, { emit }) {
    const localSelectedTags = ref([...props.selectedTags]);
    const showTagModal = ref(false);

    watch(localSelectedTags, (newTags) => {
      emit('update:selectedTags', newTags);
    }, { deep: true });

    const removeTag = (type) => {
      const index = localSelectedTags.value.indexOf(type);
      if (index !== -1) {
        localSelectedTags.value.splice(index, 1);
      }
    };

    const clearTags = () => {
      localSelectedTags.value = [];
    };

    return {
      localSelectedTags,
      showTagModal,
      removeTag,
      clearTags
    };
  }
}
</script>