<template>
  <v-card elevation="2" class="pa-1 mb-2">
    <div class="text-h6 mb-1">Schedules</div>
    <v-row v-for="(schedule, index) in localSchedules" :key="index" class="schedule-row py-0 align-center">

      <!-- Day Display -->
      <v-col :cols="isMobile ? 1 : 1" class="d-flex justify-center align-center">
        <div class="day-display">{{ schedule.day }}</div>
      </v-col>

      <!-- Opening Time -->
      <v-col :cols="isMobile ? 6 : 5" class="d-flex justify-center">
        <v-text-field v-model="schedule.openTime" type="time" suffix="EST" :label="index === 0 ? 'Opening' : 'Opening'"
          class="text-field-no-margin"></v-text-field>
      </v-col>

      <!-- Closing Time -->
      <v-col :cols="isMobile ? 6 : 5" class="d-flex justify-center">
        <v-text-field v-model="schedule.closeTime" type="time" suffix="EST" :label="index === 0 ? 'Closing' : 'Closing'"
          class="text-field-no-margin"></v-text-field>
      </v-col>

    </v-row>
  </v-card>
</template>



<script>
import { ref, watch } from 'vue';

export default {
  name: 'SchedulesSection',
  props: {
    schedules: Array
  },
  setup(props, { emit }) {
    const localSchedules = ref([...props.schedules]);

    watch(localSchedules, (newSchedules) => {
      emit('update:schedules', newSchedules);
    }, { deep: true });


    const windowWidth = ref(window.innerWidth);

    const isMobile = ref(windowWidth.value < 800);


    return {
      localSchedules,
      isMobile
    };
  }
}
</script>

<style scoped>
.schedule-row {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: -30px;
}

.day-display {
  font-weight: bold;
  font-size: 0.55em;
}


.v-text-field>>>input {
  font-size: 0.6em;
  font-weight: bold;
  text-transform: capitalize;
}

.v-text-field>>>label {
  font-size: 0.5em;
  font-weight: 500;
}


@media (min-width: 600px) {
  .schedule-row {
    max-width: 95%;
    margin-bottom: -30px;
  }

  .day-display {
    font-weight: 500;
    font-size: 1em;
  }

  .v-text-field>>>input {
    font-size: 1.1em;
    font-weight: bold;
    text-transform: capitalize;
  }

  .v-text-field>>>label {
    font-size: 0.8em;
  }
}
</style>