<template>
    <div>
      <CampingForm />
    </div>
  </template>
  
  <script>
  import CampingForm from '@/components/CampingForm/CampingForm.vue';
  
  export default {
    name: 'AddCamping',
    components: {
      CampingForm
    }
  }
  </script>
  
  <style scoped></style>