import api from '@/api/apiClient.js';
import { LOGIN_ENDPOINT, REGISTER_ENDPOINT, REFRESH_TOKEN_ENDPOINT, LOGOUT_ENDPOINT } from '@/api/endpoints.js';

const login = async (username, password) => {
    try {
        const response = await api.post(LOGIN_ENDPOINT, { username, password }, {
            headers: { withoutAuth: true }
        });
        return response.data;
    } catch (error) {
        console.error('There was an error during login:', error);
        throw error;
    }
};

const register = async (userRegistrationRequestDto) => {
    try {
        const response = await api.post(REGISTER_ENDPOINT, userRegistrationRequestDto, {
            headers: { withoutAuth: true }
        });
        return response.data;
    } catch (error) {
        console.error('There was an error during registration:', error);
        throw error;
    }
};


const refreshToken = async () => {
    try {
        console.log("Refreshing token with REFRESH_TOKEN_ENDPOINT:", { REFRESH_TOKEN_ENDPOINT });
        const response = await api.post(REFRESH_TOKEN_ENDPOINT, {}, {
            // Depending on your backend setup, you might need to include the current refresh token here
            headers: { withoutAuth: true }
        });
        return response.data;  // This return a new access token.
    } catch (error) {
        console.error('There was an error during token refresh:', error);
        throw error;
    }
};

const logout = async () => {
    try {
        console.log("Logging out with LOGOUT_ENDPOINT:", { LOGOUT_ENDPOINT });
        const response = await api.post(LOGOUT_ENDPOINT, {}, {
            // This might require the current access token or refresh token, depending on your setup
        });
        return response.data;
    } catch (error) {
        console.error('There was an error during logout:', error);
        throw error;
    }
};

export default {
    login,
    register,
    refreshToken,
    logout
};