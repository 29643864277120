<template>
  <v-card elevation="2" class="pa-3 mb-4">
    <h3 class="text-h6 mb-2">Facility Types</h3>

    <!-- Button to open modal -->
    <v-btn @click="showFacilityModal = true" class="btn-camping">Select Facility Types</v-btn>

    <!-- Display selected facility types using chips -->
    <v-chip-group v-if="localSelectedFacilities.length" class="mt-3">
      <v-chip v-for="(type) in localSelectedFacilities" :key="type" closable @click:close.prevent="removeFacility(type)">
        {{ facilityTypeOptions.find(item => item.value === type).title }}
      </v-chip>
    </v-chip-group>

    <!-- Modal for selecting facility types -->
    <v-dialog v-model="showFacilityModal" max-width="800px">
      <v-card>
        <v-card-title>Select Facility Types</v-card-title>
        <v-card-text>
          <v-row>
            <v-col v-for="(type, key) in facilityTypeOptions" :key="key" cols="4">
              <v-checkbox v-model="localSelectedFacilities" :value="type.value" :label="type.title"></v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="clearFacilities" color="red">Clear All</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="showFacilityModal = false" class="btn-camping">Add</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-card>
</template>

<script>
import { ref, watch } from 'vue';

export default {
  name: 'FacilityTypeSection',
  props: {
    selectedFacilities: {
      type: Array,
      default: () => []
    },
    facilityTypeOptions: {
      type: Array,
      default: () => []
    }
  },
  setup(props, { emit }) {
    const localSelectedFacilities = ref([...props.selectedFacilities]);
    const showFacilityModal = ref(false);

    watch(localSelectedFacilities, (newFacilities) => {
      emit('update:selectedFacilities', newFacilities);
    }, { deep: true });

    const removeFacility = (type) => {
      const index = localSelectedFacilities.value.indexOf(type);
      if (index > -1) {
        localSelectedFacilities.value.splice(index, 1);
      }
    };

    const clearFacilities = () => {
      localSelectedFacilities.value = [];
    };

    return {
      localSelectedFacilities,
      showFacilityModal,
      clearFacilities,
      removeFacility 
    };
  }
}
</script>

<style scoped></style>