<template>
  <v-card elevation="2" class="pa-3 mb-4">
    <h3 class="text-h6 mb-2">Operating Months</h3>
    <v-row v-for="(season, index) in localSeasonalities" :key="index">
      <v-col cols="5">
        <v-select :items="months" v-model="localSeasonalities[index].startMonth" label="Start Month"></v-select>
      </v-col>
      <v-col cols="5">
        <v-select :items="months" v-model="localSeasonalities[index].endMonth" label="End Month"></v-select>
      </v-col>
      <v-col cols="2" class="d-flex align-center">
        <v-btn icon @click="removeLocalSeasonality(index)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-btn v-if="localSeasonalities.length < 7" @click="emitAddSeasonality" class="btn-camping mt-2">
      <v-icon left>mdi-plus</v-icon>
      Add Season
    </v-btn>
    <p v-else class="mt-2">You have reached the maximum limit of 7 operating months.</p>
  </v-card>
</template>

<script>
import { ref, watch } from 'vue';

export default {
  props: {
    seasonalities: {
      type: Array,
      default: () => [],
    }
  },

  setup(props, { emit }) {
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const monthMapping = {
      'January': 1,
      'February': 2,
      'March': 3,
      'April': 4,
      'May': 5,
      'June': 6,
      'July': 7,
      'August': 8,
      'September': 9,
      'October': 10,
      'November': 11,
      'December': 12
    };
    const localSeasonalities = ref([...props.seasonalities]);

    watch(localSeasonalities, (newVal) => {
      const transformedSeasonalities = newVal.map(season => ({
        startMonth: monthMapping[season.startMonth],
        endMonth: monthMapping[season.endMonth]
      }));

      emit('update:seasonalities', transformedSeasonalities);
    }, { deep: true });

    const emitAddSeasonality = () => {
      if (localSeasonalities.value.length < 7) {
        localSeasonalities.value.push({
          startMonth: months[0],
          endMonth: months[11]
        });
      }
    };

    const removeLocalSeasonality = (index) => {
      localSeasonalities.value.splice(index, 1);
    };

    return {
      months,
      localSeasonalities,
      emitAddSeasonality,
      removeLocalSeasonality
    };
  }
}
</script>

<style scoped></style>