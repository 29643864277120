<template>
    <v-container>

        <v-row class="justify-center">
            <v-col cols="12" md="8">
                <div v-show="isLoading" class="loading">Please wait...</div>

                <v-card elevation="2" class="pa-3 mb-4">
                    <v-row class="ma-0">
                        <v-col class="pa-3 d-flex justify-start align-center" :cols="isMobile ? 8 : 10">
                            <h2>Add a Camping</h2>
                        </v-col>
                        <v-col class="pa-3 d-flex justify-end align-center" :cols="isMobile ? 4 : 2">
                            <v-switch :input-value="camping.campsiteType === 1"
                                @change="camping.campsiteType = camping.campsiteType === 1 ? 0 : 1"
                                :label="camping.campsiteType === 1 ? 'Free' : 'Paid'"
                                :color="camping.campsiteType === 1 ? 'green' : 'yellow'" class="custom-switch">
                            </v-switch>
                        </v-col>
                    </v-row>
                </v-card>

                <v-form ref="form" @submit.prevent="submitForm">

                    <!-- CampingDetails section-->
                    <CampingDetailsSection :details="camping" @update:details="updateDetails" :regionOptions="regionOptions"
                        :campsiteType="camping.campsiteType" />

                    <!-- Address Section -->
                    <AddressSection :address="camping.address" :campsiteType="camping.campsiteType"
                        @update:address="updateAddress" />

                    <!-- SeasonalitySection -->
                    <SeasonalitySection v-if="camping.campsiteType !== 1" :seasonalities="camping.seasonalities"
                        @update:seasonalities="updateSeasonalities" />

                    <!-- Contacts Section -->
                    <ContactsSection v-if="camping.campsiteType !== 1" :contacts="camping.contacts"
                        :contactTypeOptions="contactTypeOptions" @update:contacts="updateContacts" />

                    <!-- Photos Section -->
                    <PhotosSection :photos="camping.photos" @update:photos="updatePhotos" />

                    <!-- AccommodationType Section -->
                    <AccommodationTypeSection v-if="camping.campsiteType !== 1"
                        :selectedAccommodations="camping.selectedAccommodations"
                        :accommodationTypeOptions="accommodationTypes"
                        @update:selectedAccommodations="updateSelectedAccommodations" />

                    <!--FacilityType Section -->
                    <FacilityTypeSection :selectedFacilities="camping.selectedFacilities"
                        :facilityTypeOptions="facilityTypes" @update:selectedFacilities="updateSelectedFacilities" />

                    <!-- TagType Section -->
                    <TagTypeSection :selectedTags="camping.selectedTags" :tagTypeOptions="tagTypes"
                        @update:selectedTags="updateSelectedTags" />

                    <!-- Schedules Section -->
                    <SchedulesSection v-if="camping.campsiteType !== 1" :schedules="camping.schedules"
                        @updateSchedules="updateSchedules" />

                    <!-- Insert LoisSection where you want it to appear -->
                    <LoisSection :loiTypeOptions="loiTypeOptions" :lois="camping.lois" @update:lois="updateLois" />

                    <!-- Success Dialog -->
                    <v-dialog v-model="successDialogVisible" max-width="800px" bottom>
                        <v-card>
                            <v-card-title class="headline text-center green--text">
                                <v-icon large color="green">mdi-check-circle-outline</v-icon> Success
                            </v-card-title>
                            <v-card-text class="text-center font-weight-bold">Camping site added successfully!</v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="green darken-1" text @click="successDialogVisible = false">Close</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>


                    <!-- Error Dialog -->
                    <v-dialog v-model="errorDialogVisible" max-width="800px">
                        <v-card>
                            <v-card-title class="headline text-center red--text">
                                <v-icon large color="red">mdi-alert-circle-outline</v-icon> Error
                            </v-card-title>
                            <v-card-text class="text-center font-weight-bold">{{ errorMessage }}</v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="red darken-1" text @click="errorDialogVisible = false">Close</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>

                    <v-row class="justify-end mb-4">
                        <v-col cols="auto">
                            <v-btn type="submit" class="btn-submit">Add Camping</v-btn>
                        </v-col>
                    </v-row>

                </v-form>
            </v-col>
        </v-row>

    </v-container>
</template>
  
<script>
import { reactive, ref } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import CampingDetailsSection from './CampingDetailsSection.vue';
import AddressSection from './AddressSection.vue';
import ContactsSection from './ContactsSection.vue';
import PhotosSection from './PhotosSection.vue';
import AccommodationTypeSection from './AccommodationTypeSection.vue';
import FacilityTypeSection from './FacilityTypeSection.vue';
import TagTypeSection from './TagTypeSection.vue';
import SchedulesSection from './SchedulesSection.vue';
import SeasonalitySection from './SeasonalitySection.vue';
import LoisSection from './LoisSection.vue';

import { regionOptions, campsiteTypeOptions, contactTypeOptions, accommodationTypes, facilityTypes, tagTypes, loiTypeOptions } from './campingOptionsConfig';
import { campingValidationRules } from './validationRules.js';
import campingService from '@/api/services/campingService.js';
import { useRouter, useRoute } from 'vue-router'



export default {
    watch: {
        'camping.campsiteType': {
            immediate: true,  // To handle initial rendering as well
            handler(newVal) {
                if (newVal === 1) {
                    document.body.classList.add('free-campsite');
                    document.body.classList.remove('paid-campsite');
                } else {
                    document.body.classList.add('paid-campsite');
                    document.body.classList.remove('free-campsite');
                }
            }
        }
    },
    components: {
        CampingDetailsSection,
        AddressSection,
        ContactsSection,
        PhotosSection,
        AccommodationTypeSection,
        FacilityTypeSection,
        TagTypeSection,
        SchedulesSection,
        SeasonalitySection,
        LoisSection
    },
    computed: {
        containerClass() {
            return this.camping.campsiteType === 1 ? 'free-campsite' : 'paid-campsite';
        }
    },
    setup() {
        const camping = reactive({
            name: '',
            icon: '',
            priceLevel: 3,
            description: '',
            coverPhoto: '',
            rating: 4,
            region: null,
            campsiteType: 0,
            address: {
                street: '',
                number: '',
                city: '',
                country: '',
                postalCode: '',
                latitude: null,
                longitude: null
            },
            contacts: [
                { contactType: 0, value: '' }
            ],
            photos: [''],
            schedules: [
                { day: 'Monday', openTime: '09:00', closeTime: '20:00' },
                { day: 'Tuesday', openTime: '09:00', closeTime: '20:00' },
                { day: 'Wednesday', openTime: '09:00', closeTime: '20:00' },
                { day: 'Thursday', openTime: '09:00', closeTime: '20:00' },
                { day: 'Friday', openTime: '09:00', closeTime: '20:00' },
                { day: 'Saturday', openTime: '09:00', closeTime: '20:00' },
                { day: 'Sunday', openTime: '09:00', closeTime: '20:00' }
            ],
            seasonalities: [
                { startMonth: "April", endMonth: "October" }
            ],
            accommodationTypes: [],
            facilityTypes: [],
            tagTypes: [],
            lois: []
        });

        const router = useRouter()
        const route = useRoute()

        const isLoading = ref(false);

        console.log(router)
        console.log(route)

        const dayToNumberMap = {
            'Monday': 1,
            'Tuesday': 2,
            'Wednesday': 3,
            'Thursday': 4,
            'Friday': 5,
            'Saturday': 6,
            'Sunday': 7
        };

        const mapDaysToNumbers = (schedules) => {
            return schedules.map(schedule => ({
                ...schedule,
                day: dayToNumberMap[schedule.day]
            }));
        };

        const monthToNumberMap = {
            'January': 1,
            'February': 2,
            'March': 3,
            'April': 4,
            'May': 5,
            'June': 6,
            'July': 7,
            'August': 8,
            'September': 9,
            'October': 10,
            'November': 11,
            'December': 12
        };

        const mapMonthsToNumbers = (seasonalities) => {
            return seasonalities.map(seasonality => ({
                ...seasonality,
                startMonth: monthToNumberMap[seasonality.startMonth],
                endMonth: monthToNumberMap[seasonality.endMonth]
            }));
        };
        const windowWidth = ref(window.innerWidth);
        const isMobile = ref(windowWidth.value < 800);


        // Add a ref to handle the dialog visibility
        const successDialogVisible = ref(false);
        const errorDialogVisible = ref(false);
        const errorMessage = ref('');

        //Update Methods
        const updateDetails = (newDetails) => {
            camping.name = newDetails.name;
            camping.icon = newDetails.icon;
            camping.priceLevel = newDetails.priceLevel;
            camping.description = newDetails.description;
            camping.coverPhoto = newDetails.coverPhoto;
            camping.rating = newDetails.rating;
            camping.region = newDetails.region;
        };

        const updateAddress = (newAddress) => {
            camping.address = newAddress;
        };

        const updateContacts = (newContacts) => {
            camping.contacts = newContacts;
        }

        const updatePhotos = (updatedPhotos) => {
            camping.photos = updatedPhotos;
        }

        const updateSelectedAccommodations = (updatedAccommodationTypes) => {
            camping.accommodationTypes = updatedAccommodationTypes;
        };

        const updateSelectedFacilities = (updatedFacilities) => {
            camping.facilityTypes = updatedFacilities;
        };

        const updateSelectedTags = (updatedTags) => {
            camping.tagTypes = updatedTags;
        };

        const updateSeasonalities = (seasonalities) => {
            camping.seasonalities = seasonalities;
        }

        const updateSchedules = (schedules) => {
            camping.schedules = schedules;
        }

        const updateLois = (updatedLois) => {
            camping.lois = updatedLois;
        };

        // Selected arrays
        const selectedAccommodations = ref([]);
        const selectedFacilities = ref([]);
        const selectedTags = ref([]);


        const isDefaultSchedule = (schedule) => (
            schedule.day === null &&
            schedule.openTime === '' &&
            schedule.closeTime === ''
        );

        const isDefaultContact = (contact) => (
            contact.value === null || contact.value === ''
        );

        const validations = useVuelidate(campingValidationRules, camping);
        const hasValidationErrors = () => validations.value.$invalid == true;

        const submitForm = () => {   
            isLoading.value = true;
            
            if (camping.schedules.length === 1 && isDefaultSchedule(camping.schedules[0])) {
                camping.schedules = [];
            }

            if (camping.contacts.length === 1 && isDefaultContact(camping.contacts[0])) {
                camping.contacts = [];
            }

            if (hasValidationErrors()) {
                isLoading.value = false;
                errorMessage.value = "There are errors in the form.";
                errorDialogVisible.value = true;
                return;
            }

            postCampingData();
        };

        const postCampingData = async () => {
            try {
                const mappedCampingData = {
                    ...camping,
                    schedules: mapDaysToNumbers(camping.schedules),
                    seasonalities: mapMonthsToNumbers(camping.seasonalities)
                };

                const response = await campingService.postCampingData(mappedCampingData);
                isLoading.value = false;
                console.log(response);
                resetForm();
                successDialogVisible.value = true;  // Show success dialog

            } catch (error) {
                console.error('There was an error posting the data:', error);
                errorMessage.value = error.response?.data?.message || 'An unexpected error occurred.';
                errorDialogVisible.value = true;
            } finally {
                isLoading.value = false;
            }
        };

        const resetForm = () => {
            camping.name = '';
            camping.icon = '';
            camping.priceLevel = null;
            camping.description = '';
            camping.coverPhoto = '';
            camping.rating = null;
            camping.region = null;
            camping.campsiteType = 0;
            camping.address = {
                street: '',
                number: '',
                city: '',
                country: '',
                postalCode: '',
                latitude: null,
                longitude: null
            };
            camping.contacts = [
                { contactType: 0, value: '' }
            ];
            camping.photos = [''];
            camping.schedules = [
                { day: 'Monday', openTime: '09:00', closeTime: '20:00' },
                { day: 'Tuesday', openTime: '09:00', closeTime: '20:00' },
                { day: 'Wednesday', openTime: '09:00', closeTime: '20:00' },
                { day: 'Thursday', openTime: '09:00', closeTime: '20:00' },
                { day: 'Friday', openTime: '09:00', closeTime: '20:00' },
                { day: 'Saturday', openTime: '09:00', closeTime: '20:00' },
                { day: 'Sunday', openTime: '09:00', closeTime: '20:00' }
            ];
            camping.seasonalities = [
                { startMonth: null, endMonth: null }
            ];
            camping.accommodationTypes = [];
            camping.facilityTypes = [];
            camping.tagTypes = [];
        };

        return {
            camping,

            regionOptions,
            campsiteTypeOptions,
            loiTypeOptions,
            contactTypeOptions,
            accommodationTypes,
            facilityTypes,
            tagTypes,

            selectedAccommodations,
            selectedFacilities,
            selectedTags,

            updateSchedules,
            updateDetails,
            updateAddress,
            updateContacts,
            updatePhotos,
            updateSelectedAccommodations,
            updateSelectedFacilities,
            updateSelectedTags,
            updateSeasonalities,
            isLoading,
            updateLois,
            submitForm,
            mapMonthsToNumbers,
            successDialogVisible,
            isMobile,
            errorDialogVisible,
            errorMessage,
            resetForm,
            validations
        };
    },
};
</script>
  
<style>
@import './styles.css';

body.free-campsite .v-application {
    background-color: rgba(0, 128, 0, 0.055) !important;
}

body.paid-campsite .v-application {
    background-color: rgba(255, 255, 0, 0.055) !important;
}

.v-selection-control__wrapper,
.v-switch__thumb {
    color: rgb(197, 186, 33);
}


.custom-switch .v-switch__track {
    height: 35px;
    width: 80px;
    border-radius: 15px;
}

.custom-switch .v-label {
    font-size: 1.5rem;
    font-weight: bold;
}

.custom-switch .v-selection-control__wrapper {
    width: 60px;
    top: 1px;
}

.custom-switch .v-switch__thumb {
    width: 32px;
    height: 30px;
}

.loading {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 20px;
    border-radius: 5px;
    z-index: 1000;
}
</style>