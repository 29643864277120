<template>
  <v-card elevation="2" class="pa-3 mb-4">
    <h3 class="text-h6 mb-2">Accommodation Types</h3>

    <v-btn class="btn-camping" @click="showAccommodationsModal = true">Select Accommodation Types</v-btn>

    <v-chip-group column v-if="localSelectedAccommodations.length" class="mt-3">
      <!-- For each selected accommodation type, display a chip with a close button -->
      <v-chip v-for="(type) in localSelectedAccommodations" :key="type" closable @click:close="removeAccommodation(type)">
        {{ accommodationTypeOptions.find(item => item.value === type).title }}
      </v-chip>
    </v-chip-group>

    <!-- Modal for selecting accommodation types -->
    <v-dialog v-model="showAccommodationsModal" max-width="800px">
      <v-card>
        <v-card-title>Select Accommodation Types</v-card-title>
        <v-card-text>
          <v-row>
            <v-col v-for="(type, key) in accommodationTypeOptions" :key="key" cols="4">
              <v-checkbox v-model="localSelectedAccommodations" :value="type.value" :label="type.title"></v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="clearAccommodations" color="red">Clear All</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="showAccommodationsModal = false" class="btn-camping">Add</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-card>
</template>

<script>
import { ref, watch } from 'vue';

export default {
  name: 'AccommodationTypeSection',
  props: {
    selectedAccommodations: {
      type: Array,
      default: () => []
    },
    accommodationTypeOptions: {
      type: Array,
      default: () => []
    }
  },
  setup(props, { emit }) {
    const localSelectedAccommodations = ref([...props.selectedAccommodations]);
    const showAccommodationsModal = ref(false);

    watch(localSelectedAccommodations, (newAccommodations) => {
      emit('update:selectedAccommodations', newAccommodations);
    }, { deep: true });

    const removeAccommodation = (type) => {
      const index = localSelectedAccommodations.value.indexOf(type);
      if (index !== -1) {
        localSelectedAccommodations.value.splice(index, 1);
      }
    };

    const clearAccommodations = () => {
      localSelectedAccommodations.value = [];
    };

    return {
      localSelectedAccommodations,
      showAccommodationsModal,
      removeAccommodation,
      clearAccommodations
    };
  }
}
</script>