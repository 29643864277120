import { required, url } from '@vuelidate/validators';

const isPhone = (value) => /^\d+$/.test(value) && value.length <= 12;
const isValidUrl = (value) => /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/.test(value) && value.length <= 1400;
const isEmail = (value) => /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(value) && value.length <= 32;


// Dynamic validator based on contactType
export const contactValueValidator = (contact) => {
    switch (contact.contactType) {
        case 0: // Mobile
        case 1: // Phone
            if (!isPhone(contact.value)) {
                return 'Invalid phone number';
            }
            break;
        case 2: // Email
            if (!isEmail(contact.value)) {
                return 'Invalid email address';
            }
            break;
        case 3: // Website
        case 4: // Instagram
        case 6: // Twitter
        case 7: // Whatsup
        case 5: // Facebook
            if (!isValidUrl(contact.value)) {
                return 'Invalid URL';
            }
            break;
        default:
            return true;
    }
    return true;
};


export const campingValidationRules = {
    name: { required },
    icon: { url },
    priceLevel: { required },
    description: { required },
    coverPhoto: { url },
    rating: {},
    region: { required },
    campsiteType: { required },
    address: {
        street: {},
        number: {},
        city: { required },
        country: { required },
        postalCode: { },
        latitude: { required },
        longitude: { required }
    },
    contacts: {
        $each: {
            contactType: { required },
            value: (value, parent) => {
                return contactValueValidator(parent);
            }
        }
    },
    photos: {
        $each: {
            required
        }
    }
};