<template>
  <v-container class="camping-details">
  <!-- Loading State -->
  <v-row v-if="!camping && !error" justify="center" align="center" class="full-height">
      <v-progress-circular indeterminate></v-progress-circular>
    </v-row>

    <!-- Error State -->
    <v-row v-if="error" justify="center" align="center" class="full-height">
      <v-alert type="error" dense outlined rounded class="error-alert">
        Oops! Something went wrong. <br> <br>{{ error }}
      </v-alert>
    </v-row>



    <!-- Main Content -->
    <div v-if="camping">
      <v-img :src="camping.coverPhoto" aspect-ratio="2.75" class="cover-photo"></v-img>

      <v-card flat class="camping-info">
        <v-card-title class="headline">{{ camping.name }}</v-card-title>
        <v-card-subtitle>{{ camping?.rating }} ★</v-card-subtitle>
        <v-card-text>{{ camping.description }}</v-card-text>
      </v-card>

      <v-card flat class="camping-address">
        <v-card-title>Location</v-card-title>
        <v-card-text>
          {{ camping.address?.street }} {{ camping.address?.number }}<br>
          {{ camping.address?.city }}, {{ camping.address?.country }}<br>
          {{ camping.address?.postalCode }}
        </v-card-text>
        <!-- Map placeholder -->
        <v-divider></v-divider>
        <div class="map"></div>
      </v-card>

      <v-card flat class="camping-schedule">
        <v-card-title>Opening Hours</v-card-title>
        <v-list dense>
          <v-list-item v-for="schedule in camping.schedules" :key="schedule.day">
            <v-list-item-content>
              {{ schedule.day }}: {{ schedule.openTime }} - {{ schedule.closeTime }}
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>

      <v-card flat class="camping-season">
        <v-card-title>Seasonal Operation</v-card-title>
        <v-list dense>
          <v-list-item v-for="season in camping.seasonalities" :key="season.startMonth">
            <v-list-item-content>
              {{ season.startMonth }} - {{ season.endMonth }}
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>

      <v-card flat class="camping-photos">
        <v-card-title>Photos</v-card-title>
        <v-row>
          <v-col v-for="photo in camping.photos" :key="photo" cols="12" sm="4">
            <v-img :src="photo" aspect-ratio="1.5"></v-img>
          </v-col>
        </v-row>
      </v-card>
    </div>
  </v-container>
</template>



<script>
import { ref, onMounted } from 'vue';
import campingService from '@/api/services/campingService.js';

export default {
  name: 'CampingDetails',
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  setup(props) {
    const camping = ref(null);
    const error = ref(null);

    const loadCampingDetails = async () => {
      try {
        const id = props.id || "ed294aaa-a0a9-43e0-d139-08db9c31bc5e";

        const data = await campingService.fetchCampingDetails(id);
        camping.value = data;
      } catch (err) {
        error.value = err.message;
      }
    };

    onMounted(loadCampingDetails);

    return {
      camping,
      error
    };
  }
};
</script>

  
<style scoped>
.camping-details {
  min-height: 100vh; /* Minimum height equal to the viewport height */
  display: flex; /* Flexbox layout */
  flex-direction: column; /* Direct children layout in a column */
  justify-content: center; /* Center direct children along the vertical axis */
}

.cover-photo {
  border-radius: 10px;
}

.map {
  height: 200px;
  background: #eaeaea;
  border-radius: 5px;
}

.full-height {
  flex: 1; /* Allow this element to grow and occupy available space */
  display: flex; /* Flexbox layout */
  align-items: center; /* Center child items vertically */
  justify-content: center; /* Center child items horizontally */
}

.error-alert {
  max-width: 70%;
  text-align: center;
  padding: 2em; 
}
</style>