<template>
    <v-card elevation="2" class="pa-3 mb-4">
      <h3 class="text-h6 mb-2">Photos</h3>
      <v-row v-for="(photo, index) in localPhotos" :key="index">
        <v-col cols="10">
          <v-text-field
            label="Photo URL"
            v-model="localPhotos[index]"
            :error-messages="getPhotoErrors(index)"
            required
            @input="clearPhotoError(index)"
            :rules="getPhotoValidationRules()"  
          ></v-text-field>
        </v-col>
        <v-col cols="2" class="text-center">
          <v-btn icon @click="emitRemovePhoto(index)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-btn @click="emitAddPhoto" class="btn-camping mt-2">
        <v-icon left>mdi-plus</v-icon>Add More Photos
      </v-btn>
    </v-card>
  </template>
  
  <script>
  import { ref, watch } from 'vue';
  import { required } from '@vuelidate/validators';
  
  export default {
    props: ['photos'],
    setup(props, { emit }) {
      const localPhotos = ref([...props.photos]);
      const photoErrors = ref([]);
  
      watch(localPhotos, (newVal) => {
        emit('update:photos', newVal);
      }, { deep: true });
  
      const emitAddPhoto = () => {
        localPhotos.value.push('');
        photoErrors.value.push([]);
      };
  
      const emitRemovePhoto = (index) => {
        localPhotos.value.splice(index, 1);
        photoErrors.value.splice(index, 1);
      };
  
      const getPhotoErrors = (index) => photoErrors.value[index] || [];
  
      const clearPhotoError = (index) => {
        photoErrors.value[index] = [];
      };
  
      const getPhotoValidationRules = () => [
        required,
        value => /^https?:\/\/[\S]+$/.test(value) || 'Invalid URL format'
      ];
  
      return {
        localPhotos,
        emitAddPhoto,
        emitRemovePhoto,
        getPhotoErrors,
        clearPhotoError,
        getPhotoValidationRules,
      };
    },
  };
  </script>
  
  <style scoped></style>