export const regionOptions = [
    { title: 'Athens', value: 0 },
    { title: 'Central Greece', value: 1 },
    { title: 'Peloponnese', value: 2 },
    { title: 'Macedonia', value: 3 },
    { title: 'Epirus', value: 4 },
    { title: 'Ionian Islands', value: 5 },
    { title: 'Aegean Islands', value: 6 },
    { title: 'Crete', value: 7 },
    { title: 'Thrace', value: 8 }
];


export const campsiteTypeOptions = [
    { title: 'Paid', value: 0 },
    { title: 'Free', value: 1 },
];


export const contactTypeOptions = [
    { title: 'Mobile', value: 0 },
    { title: 'Phone', value: 1 },
    { title: 'Email', value: 2 },
    { title: 'Website', value: 3 },
    { title: 'Instagram', value: 4 },
    { title: 'Facebook', value: 5 },
    { title: 'Twitter', value: 6 },
    { title: 'Whatsup', value: 7 }
];

export const accommodationTypes = [
    { title: 'Bungalow', value: 0 },
    { title: 'Tent', value: 1 },
    { title: 'Caravan', value: 2 },
    { title: 'Hotel Room', value: 3 },
    { title: 'Hostel Bed', value: 4 },
    { title: 'Cottage', value: 5 },
    { title: 'Apartment', value: 6 },
    { title: 'Studio', value: 7 },
    { title: 'Bed and Breakfast', value: 8 },
    { title: 'Glamping Tent', value: 9 },
    { title: 'Motorhome', value: 10 },
    { title: 'Yurt', value: 11 },
    { title: 'Treehouse', value: 12 },
    { title: 'Chalet', value: 13 }
];


export const facilityTypes = [
    { title: 'Pool', value: 0 },
    { title: 'Kitchen', value: 1 },
    { title: 'Beach', value: 2 },
    { title: 'WC', value: 3 },
    { title: 'Tent', value: 4 },
    { title: 'Restaurant', value: 5 },
    { title: 'Rentals', value: 6 },
    { title: 'RV Support Area', value: 7 },
    { title: 'Pet Friendly', value: 8 },
    { title: 'Wifi', value: 9 },
    { title: 'Swimming Pool', value: 10 },
    { title: 'Mini Market', value: 11 },
    { title: 'Electricity', value: 12 },
    { title: 'Toilets', value: 13 },
    { title: 'Playground', value: 14 },
    { title: 'Laundry', value: 15 },
    { title: 'Grills', value: 16 },
    { title: 'Refrigerators', value: 17 },
    { title: 'Showers', value: 18 },
    { title: 'Water Supply', value: 19 },
    { title: 'Chemical Toilet Discharge', value: 20 }
];


export const tagTypes = [
    { title: 'User Favorite', value: 0 },
    { title: 'Hidden Gem', value: 1 },
    { title: 'Photogenic', value: 2 },
    { title: 'Festival Hotspot', value: 3 },
    { title: 'Foodie Paradise', value: 4 },
    { title: 'Nightlife', value: 5 },
    { title: 'Wildlife', value: 6 },
    { title: 'Historic', value: 7 },
    { title: 'Art Lovers', value: 8 },
    { title: 'Sports Activities', value: 9 },
    { title: 'Shopping', value: 10 }
];

export const loiTypeOptions = [
    { title: 'SEA', value: 0 },
    { title: 'RIVER', value: 1 },
    { title: 'SUPERMARKET', value: 2 },
    { title: 'CHORA', value: 3 },
    { title: 'GREAT_VIEW', value: 4 },
    { title: 'MOUNTAIN', value: 5 },
    { title: 'FOREST', value: 6 },
    { title: 'LAKE', value: 7 },
    { title: 'BEACH', value: 8 },
    { title: 'HISTORICAL_SITES', value: 9 },
    { title: 'MUSEUM', value: 10 },
    { title: 'NIGHTLIFE', value: 11 },
    { title: 'RESTAURANTS', value: 12 },
    { title: 'SHOPPING', value: 13 },
    { title: 'PARKS', value: 14 },
    { title: 'SPORTS_FACILITIES', value: 15 },
    { title: 'FESTIVAL_GROUNDS', value: 16 },
    { title: 'THEATRE', value: 17 },
    { title: 'MARINA', value: 18 },
    { title: 'VINEYARD', value: 19 },
    { title: 'BREWERY', value: 20 },
    { title: 'NATIONAL_PARK', value: 21 },
    { title: 'SKI_RESORT', value: 22 },
    { title: 'UNIVERSITY', value: 23 },
    { title: 'HOSPITAL', value: 24 }
];