import api from '@/api/apiClient.js';
import { CAMPING_ENDPOINT, CAMPING_DETAILS_ENDPOINT } from '@/api/endpoints.js'


const postCampingData = async (camping) => {
    try {
        console.log("JSON representation:", JSON.stringify(camping, null, 2));
        const response = await api.post(CAMPING_ENDPOINT, camping, {
            headers: { withoutAuth: true }
        });
        return response.data;
    } catch (error) {
        console.error('There was an error posting the data:', error);
        throw error;
    }
};


const fetchCampingDetails = async (campingId) => {
    try {
        const url = CAMPING_DETAILS_ENDPOINT.replace('${id}', campingId);
        console.log(url);
        const response = await api.get(url, {
            headers: { withoutAuth: true }
        });
        return response.data;
    } catch (error) {
        console.error('There was an error fetching the camping details:', error);
        throw error;
    }
};


export default {
    postCampingData,
    fetchCampingDetails
};