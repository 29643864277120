<template>
  <v-card elevation="2" class="pa-3 mb-4">
    <h3 class="text-h6 mb-2">Contacts</h3>

    <v-row v-for="(contact, index) in localContacts" :key="index">
      <v-col cols="5">
        <v-select v-model="contact.contactType" :items="contactTypeOptions" item-text="title" item-value="value"
          label="Contact Type" required></v-select>
      </v-col>
      <v-col cols="5">
        <v-text-field label="Value" v-model="contact.value" :error-messages="getContactErrors(index, contact)"
          required>
        </v-text-field>
      </v-col>
      <v-col cols="2" class="d-flex align-center">
        <v-btn icon @click="removeLocalContact(index)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-btn @click="addLocalContact" class="btn-camping mt-2">
      <v-icon left>mdi-plus</v-icon>
      Add More Contact</v-btn>
  </v-card>
</template>

<script>
import { ref, watch } from 'vue';
import { contactValueValidator } from './validationRules.js';


export default {
  name: 'ContactsSection',
  props: {
    contacts: Array,
    contactTypeOptions: Array
  },
  setup(props, { emit }) {
    const localContacts = ref([...props.contacts]);

    watch(localContacts, (newContacts) => {
      emit('update:contacts', newContacts);
    }, { deep: true });

    const addLocalContact = () => {
      localContacts.value.push({ contactType: 0, value: '' });
    };

    const removeLocalContact = (index) => {
      localContacts.value.splice(index, 1);
    };


    const getContactErrors = (index, contact) => {
      //const contactt = localContacts.value[index];

      const validationResult = contactValueValidator(contact);
      if (typeof validationResult === 'string') {
        return [validationResult];
      }
      return [];
    };
 
    return {
      localContacts,
      getContactErrors,
      contactValueValidator,
      addLocalContact,
      removeLocalContact
    };
  }
}
</script>
