<template>
  <v-card elevation="2" class="pa-3 mb-4">
    <h3 class="text-h6 mb-2">Camping Details</h3>

    <!-- Name and Region Section -->
    <v-row class="mb-3">
      <v-col cols="6">
        <v-text-field label="Name" v-model="localDetails.name" class="required-label"
          :error-messages="v$.localDetails.name.$errors.map(e => e.$message)" required
          @input="v$.localDetails.name.$touch" @blur="v$.localDetails.name.$touch"></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-select v-model="localDetails.region" :items="regionOptions" class="required-label"
          :error-messages="v$.localDetails.region.$errors.map(e => e.$message)" label="Region" required
          @change="v$.localDetails.region.$touch" @blur="v$.localDetails.region.$touch"
          placeholder="Select a region"></v-select>
      </v-col>
    </v-row>

    <!-- Description Section -->
    <v-row class="mb-3">
      <v-col>
        <v-textarea label="Description" v-model="localDetails.description" class="required-label"
          :error-messages="v$.localDetails.description.$errors.map(e => e.$message)" required
          @input="v$.localDetails.description.$touch" @blur="v$.localDetails.description.$touch"></v-textarea>
      </v-col>
    </v-row>

    <!-- Camping Logo Section -->
    <v-row class="mb-3">
      <v-col v-if="localCampsiteType !== 1">
        <v-text-field label="Camping Logo URL" v-model="localDetails.icon"
          :error-messages="v$.localDetails.icon.$errors.map(e => e.$message)" required
          @input="v$.localDetails.icon.$touch" @blur="v$.localDetails.icon.$touch"></v-text-field>
      </v-col>
    </v-row>

     <!-- Cover Photo Section -->
     <v-row class="mb-3">
      <v-col v-if="localCampsiteType !== 1">
        <v-text-field label="Cover Photo URL" v-model="localDetails.coverPhoto"
          :error-messages="v$.localDetails.coverPhoto.$errors.map(e => e.$message)" required
          @input="v$.localDetails.coverPhoto.$touch" @blur="v$.localDetails.coverPhoto.$touch"></v-text-field>
      </v-col>
    </v-row>

    <!-- Price Level and Rating Section -->
    <v-row class="mb-3">
      <v-col :cols="12" v-if="localCampsiteType !== 1">
        <h4 class="mb-2">Price Level</h4>
        <v-slider v-model="localDetails.priceLevel" :min="1" :max="5" :step="1" thumb-label required
          @input="v$.localDetails.priceLevel.$touch" @blur="v$.localDetails.priceLevel.$touch">
        </v-slider>
        <div class="value-box" :style="priceLevelStyle"> {{ getPriceLevel(localDetails.priceLevel) }}</div>
      </v-col>

    </v-row>
  </v-card>
</template>
  
<script>
import { ref, watch } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required, url } from '@vuelidate/validators';
import { computed } from 'vue';

export default {
  props: ['details', 'regionOptions', 'campsiteType'],

  setup(props, { emit }) {
    const localDetails = ref({ ...props.details });
    const localCampsiteType = ref(props.campsiteType);

    // Watch for changes in campsiteType prop and update localCampsiteType
    watch(() => props.campsiteType, (newVal) => {
      localCampsiteType.value = newVal;
    });

    watch(localDetails, (newVal) => {
      emit('update:details', newVal);
    }, { deep: true });

    const getPriceLevel = (value) => {
      let description;
      switch (value) {
        case 1:
          description = "Very Cheap";
          break;
        case 2:
          description = "Affordable";
          break;
        case 3:
          description = "Moderate";
          break;
        case 4:
          description = "Expensive";
          break;
        case 5:
          description = "Very Expensive";
          break;
        default:
          description = "Unknown";
          break;
      }
      return `${value} - ${description}`;
    };

    const priceLevelStyle = computed(() => {
      let backgroundColor = '#f4f4f490';
      switch (localDetails.value.priceLevel) {
        case 1:
          backgroundColor = "#8ac39999";
          break;
        case 2:
          backgroundColor = "#abcda999";
          break;
        case 3:
          backgroundColor = "#c4d4bf99";
          break;
        case 4:
          backgroundColor = "#e0c2b199";
          break;
        case 5:
          backgroundColor = "#9f786099";
          break;
      }
      return {
        backgroundColor,
      };
    });

    const ratingStyle = computed(() => {
      let backgroundColor = '#f4f4f490';
      if (localDetails.value.rating <= 1) {
        backgroundColor = "#e5737399";
      } else if (localDetails.value.rating <= 1.5) {
        backgroundColor = "#ef9a9a99";
      } else if (localDetails.value.rating <= 2) {
        backgroundColor = "#efc5c599";
      } else if (localDetails.value.rating <= 2.5) {
        backgroundColor = "#fbe2e299";
      } else if (localDetails.value.rating <= 3) {
        backgroundColor = "#f0e6e499";
      } else if (localDetails.value.rating <= 3.5) {
        backgroundColor = "#d7e9d899";
      } else if (localDetails.value.rating <= 4) {
        backgroundColor = "#b0d5b399";
      } else if (localDetails.value.rating <= 4.5) {
        backgroundColor = "#8bc8a199";
      } else if (localDetails.value.rating <= 5) {
        backgroundColor = "#66b07899";
      }
      return {
        backgroundColor
      };
    });

    const v$ = useVuelidate({
      localDetails: {
        name: { required },
        icon: { url },
        description: { required },
        coverPhoto: { url },
        rating: { required },
        region: { required },
        priceLevel: { required },
      }
    }, { localDetails });

    return {
      v$,
      localDetails,
      localCampsiteType,
      getPriceLevel,
      priceLevelStyle,
      ratingStyle
    };
  }
};
</script>

<style scoped>

.value-box {
  padding: 8px 16px;
  background-color: #f4f4f4;
  /* Light gray background */
  border-radius: 12px;
  /* Rounded corners */
  text-align: center;
  font-size: large;
  font-weight: 500;
  /* Semi-bold font */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  /* Subtle elevation */
  margin-top: 10px;
  /* Space from the slider */
}
</style>