<template>
    <v-card elevation="2" class="pa-3 mb-4">
        <h3 class="text-h6 mb-2">Locations of Interest</h3>

        <v-row v-for="(loi, index) in localLois" :key="index" class="mb-2">
            <v-col cols="3">
                <v-select v-model="loi.loiType" :items="loiTypeOptions" item-text="title" item-value="value" label="Type"
                    required></v-select>
            </v-col>
            <v-col cols="3">
                <v-text-field v-model="loi.loiLabel" label="Label" maxlength="50" required></v-text-field>
            </v-col>
            <v-col cols="2">
                <v-text-field v-model.number="loi.latitude" label="Latitude" type="number" required></v-text-field>
            </v-col>
            <v-col cols="2">
                <v-text-field v-model.number="loi.longitude" label="Longitude" type="number" required></v-text-field>
            </v-col>
            <v-col cols="2" class="d-flex align-center">
                <v-btn icon @click="removeLoi(index)">
                    <v-icon>mdi-delete</v-icon>
                </v-btn>
            </v-col>
        </v-row>

        <v-btn @click="addLoi" class="mt-2">
            <v-icon left>mdi-plus</v-icon>
            Add Location of Interest
        </v-btn>
    </v-card>
</template>
  
<script>
import { ref, watch } from 'vue';

export default {
    name: 'LoisSection',
    props: {
        lois: Array,
        loiTypeOptions: Array
    },
    setup(props, { emit }) {
        const localLois = ref([...props.lois]);

        watch(localLois, (newVal) => {
            emit('update:lois', newVal);
        }, { deep: true });

        const addLoi = () => {
            localLois.value.push({ loiType: 0, loiLabel: '', latitude: 0, longitude: 0 });
        };

        const removeLoi = (index) => {
            localLois.value.splice(index, 1);
        };

        return { localLois, addLoi, removeLoi };
    }
};
</script>
  
<style scoped>
/* Add your styling here */
</style>