import { createRouter, createWebHistory } from 'vue-router';
import LoginScreen from './views/LoginScreen.vue';
import RegisterScreen from './views/RegisterScreen.vue';
import AddCamping from './views/AddCamping.vue';
import CampingDetails from './views/CampingDetails.vue';

import { useAuthStore } from './store/Auth.js';

const routes = [
  { path: '/', redirect: '/addcamping' },
  { path: '/login', component: LoginScreen },
  { path: '/register', component: RegisterScreen },
  { path: '/addcamping', component: AddCamping, meta: { requiresAuth: false } },
  { path: '/camping-details', component: CampingDetails },

];

const router = createRouter({
  history: createWebHistory(),
  routes
});


router.beforeEach((to, from, next) => {
  const authStore = useAuthStore();

  console.log(authStore);

  if (to.matched.some(record => record.meta.requiresAuth) && !authStore.$state.isLoggedIn) {
    next({ path: '/login' });
  } else {
    next();
  }
});

export default router;