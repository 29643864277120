<template>
    <div class="register-screen">
        <form @submit.prevent="handleRegister">
            <h2>Register</h2>

            <div class="input-group">
                <label for="username">Username:</label>
                <input type="text" id="username" v-model="username" required />
            </div>

            <div class="input-group">
                <label for="email">Email:</label>
                <input type="email" id="email" v-model="email" required />
            </div>

            <div class="input-group">
                <label for="firstName">First Name:</label>
                <input type="text" id="firstName" v-model="firstName" required />
            </div>

            <div class="input-group">
                <label for="lastName">Last Name:</label>
                <input type="text" id="lastName" v-model="lastName" required />
            </div>

            <div class="input-group">
                <label for="password">Password:</label>
                <div class="password-wrapper">
                    <input :type="showPassword ? 'text' : 'password'" id="password" v-model="password" required />
                    <span class="password-toggle" @click="togglePasswordVisibility">👁</span>
                </div>
            </div>

            <div class="input-group">
                <label for="confirmPassword">Confirm Password:</label>
                <div class="password-wrapper">
                    <input :type="showPassword ? 'text' : 'password'" id="confirmPassword" v-model="confirmPassword"
                        required />
                    <span class="password-toggle" @click="togglePasswordVisibility">👁</span>
                </div>
            </div>

            <button type="submit">Register</button>

            <p v-if="errorMessage">{{ errorMessage }}</p>

            <div>
                <p class="login-link">Already have an account? <router-link to="/login">Login</router-link></p>
            </div>

            <div v-if="isLoading" class="loading">Registering...</div>
        </form>
    </div>
</template>
  
<script>
import { ref } from 'vue';
import userService from '@/api/services/userService.js';
import { useRouter } from 'vue-router';

export default {
    setup() {
        const username = ref('');
        const firstName = ref('');
        const lastName = ref('');
        const email = ref('');
        const password = ref('');
        const confirmPassword = ref('');
        const errorMessage = ref('');
        const isLoading = ref(false);

        const showPassword = ref(false);

        const togglePasswordVisibility = () => {
            showPassword.value = !showPassword.value;
        };

        const router = useRouter();

        const handleRegister = async () => {
            if (password.value !== confirmPassword.value) {
                errorMessage.value = "Passwords do not match!";
                return;
            }

            isLoading.value = true;

            try {
                await userService.register({
                    username: username.value,
                    email: email.value,
                    firstName: firstName.value,
                    lastName: lastName.value,
                    password: password.value
                });

                router.push('/login');
            } catch (error) {
                if (error && error.response && error.response.status === 400) {
                    // Adjusted to handle the new error structure
                    if (error.response.data && error.response.data[0] && error.response.data[0].description) {
                        errorMessage.value = error.response.data[0].description;
                    } else {
                        errorMessage.value = "An error occurred during registration.";
                    }
                } else {
                    errorMessage.value = "An unexpected error occurred during registration.";
                }
            } finally {
                isLoading.value = false;
            }
        };

        return {
            username,
            firstName,
            lastName,
            email,
            password,
            confirmPassword,
            errorMessage,
            isLoading,
            handleRegister,
            showPassword,
            togglePasswordVisibility
        };
    }
};
</script>
  
<style scoped>
.register-screen {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(135deg, #f5f7fa, #c3cfe2);
    font-family: 'Arial', sans-serif;
}

h2 {
    text-align: center;
    margin-bottom: 24px;
    font-weight: 600;
    color: #333;
    width: 100%;
}

.input-group {
    margin-bottom: 20px;
}

label {
    display: block;
    margin-bottom: 8px;
    font-size: 16px;
    color: #555;
}

input[type="text"],
input[type="email"],
input[type="password"] {
    width: 100%;
    padding: 14px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f9f9f9;
    font-size: 16px;
    transition: border-color 0.3s ease;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus {
    border-color: #007BFF;
}

button {
    width: 100%;
    padding: 14px;
    background-color: #5d740d;
    border: none;
    border-radius: 4px;
    color: #fff;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

button:hover {
    background-color: #30881adc;
}

form {
    width: 100%;
    max-width: 400px;
    padding: 25px;
    margin: auto;
    background-color: #fff;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

p {
    color: red;
    font-size: 16px;
    text-align: center;
    margin-top: 14px;
}

.login-link {
    color: rgb(107, 106, 106) !important;
}


.loading {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 20px;
    border-radius: 5px;
    z-index: 1000;
}

.password-wrapper {
    position: relative;
    display: inline-block;
    width: 100%;
}

.password-toggle {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}
</style>