export const CAMPING_ENDPOINT = 'api/v1/admin/camps';

export const CAMPING_DETAILS_ENDPOINT = 'api/v1/m/campings/details/${id}';


export const LOGIN_ENDPOINT = 'api/v1/authenticate/login';
export const REGISTER_ENDPOINT = 'api/v1/authenticate/register';
export const LOGOUT_ENDPOINT = 'api/v1/authenticate/logout';
export const REFRESH_TOKEN_ENDPOINT = 'api/v1/authenticate/refresh-token';


