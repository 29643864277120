import { defineStore } from 'pinia';

export const useAuthStore = defineStore('auth', {
  state: () => ({
    user: null,
    token: null,
    isLoggedIn: false,
  }),

  getters: {
    // Using a getter to retrieve user information can be useful in some cases
    getUser() {
      return this.user;
    },
    getToken() {
      return this.token;
    },
    getIsLoggedIn() {
      return this.isLoggedIn;
    },
  },

  actions: {
    // You can pass an object containing user information and token
    login({ user, token }) {
      this.user = user;
      this.token = token;
      this.isLoggedIn = true;
    },
    logout() {
      this.user = null;
      this.token = null;
      this.isLoggedIn = false;
    },
    setToken(token) {
      this.token = token;
    },
    setUser(user) {
      this.user = user;
    },
  },
});