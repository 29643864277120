<template>
  <v-card elevation="2" class="pa-3 mb-4">
    <h3 class="text-h6 mb-2">Address</h3>

    <!-- Street and Number -->
    <v-row>
      <v-col cols="6">
        <v-text-field v-if="localCampsiteType !== 1" label="Street (Optional)" v-model="localAddress.street"
          :error-messages="v$.localAddress.street.$errors.map(e => e.$message)"
          @input="v$.localAddress.street.$touch" @blur="v$.localAddress.street.$touch">
        </v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field v-if="localCampsiteType !== 1" label="Number (Optional)" v-model="localAddress.number"
          :error-messages="v$.localAddress.number.$errors.map(e => e.$message)"
          @input="v$.localAddress.number.$touch" @blur="v$.localAddress.number.$touch">
        </v-text-field>
      </v-col>
    </v-row>

    <!-- City and Country -->
    <v-row>
      <v-col cols="6">
        <v-text-field label="City" v-model="localAddress.city" class="required-label"
          :error-messages="v$.localAddress.city.$errors.map(e => e.$message)" required
          @input="v$.localAddress.city.$touch" @blur="v$.localAddress.city.$touch">
        </v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field label="Country" v-model="localAddress.country" class="required-label"
          :error-messages="v$.localAddress.country.$errors.map(e => e.$message)" required
          @input="v$.localAddress.country.$touch" @blur="v$.localAddress.country.$touch">
        </v-text-field>
      </v-col>
    </v-row>

    <!-- Postal Code, Latitude, and Longitude -->
    <v-row>
      <v-col cols="4">
        <v-text-field label="Latitude" v-model.number="localAddress.latitude" type="number" class="required-label"
          :error-messages="v$.localAddress.latitude.$errors.map(e => e.$message)" required
          @input="v$.localAddress.latitude.$touch" @blur="v$.localAddress.latitude.$touch">
        </v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field label="Longitude" v-model.number="localAddress.longitude" type="number" class="required-label"
          :error-messages="v$.localAddress.longitude.$errors.map(e => e.$message)" required
          @input="v$.localAddress.longitude.$touch" @blur="v$.localAddress.longitude.$touch">
        </v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field label="Postal Code" v-model="localAddress.postalCode" type="number"
          :error-messages="v$.localAddress.postalCode.$errors.map(e => e.$message)"
          @input="v$.localAddress.postalCode.$touch" @blur="v$.localAddress.postalCode.$touch">
        </v-text-field>
      </v-col>
    </v-row>

  </v-card>
</template>

<script>
import { ref, watch } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
  name: 'AddressSection',
  props: ['address', 'campsiteType'],
  setup(props, { emit }) {
    const localAddress = ref({ ...props.address });
    const localCampsiteType = ref(props.campsiteType);

    watch(localAddress, (newVal) => {
      emit('update:address', newVal);
    }, { deep: true });

    // Watch for changes in campsiteType prop and update localCampsiteType accordingly
    watch(() => props.campsiteType, (newVal) => {
      localCampsiteType.value = newVal;
    });

    const v$ = useVuelidate({
      localAddress: {
        street: {},
        number: { numeric: value => /^[0-9]+$/.test(value) || 'Only digits are allowed' },
        city: { required },
        country: { required },
        postalCode: { numeric: value => /^[0-9]+$/.test(value) || 'Only digits are allowed' },
        latitude: { required },
        longitude: { required }
      }
    }, { localAddress });

    return {
      v$,
      localAddress,
      localCampsiteType
    };
  }
};
</script>